const getStatusColor = (status) => {
  switch (status) {
    case "finalized":
      return "tr-item__element-green";
    case "canceled":
      return "tr-item__element-red";
    case "pending":
      return "tr-item__element-yellow";
    default:
      return "";
  }
};
export default getStatusColor;
