import { BTC_ZERO_BALANCE } from "./constants/constants";
import formatCryptoAmount from "./formatCryptoAmount";

export const cutWithoutRounding = (value, precision) => {
  if (precision === 8 && (value === 0 || value === "0")) {
    return "0" + BTC_ZERO_BALANCE;
  }

  if (!value) {
    return 0;
  }

  const fixedValue = precision === 8 ? (+value)?.toFixed(20) : +value;
  const result = fixedValue?.toString().includes(".")
    ? (+value
        .toString()
        .slice(0, value?.toString().indexOf(".") + precision + 1))
        .toFixed(precision)
        .toString()
    : value;

  if (precision === 8) {
    return formatCryptoAmount(result);
  } else {
    return result;
  }
};
