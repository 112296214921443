import React, { useEffect, useState } from "react";
import "./MfaConnect.scss";
import Logo from "../../assets/svgs/logo.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import webSocketService from "../../services/WebSocketService";
import Header from "../../components/Header/Header";
import OTPInput from "react-otp-input";
import copy from "copy-to-clipboard";
import AppStore from "../../store/appStore";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/constants/constants";
import { logout } from "../../services/authentication";

const MfaConnect = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [pairCode, setPairCode] = useState();
  const [loading, setLoading] = useState(false);

  const getMFAUrl = async () => {
    if (AppStore.isAuth || loading) {
      return;
    }
    try {
      setLoading(true);
      const mfaUrl = await webSocketService.sendMessage("mfaGetURL", "c");

      const parsedUrl = new URL(mfaUrl?.url);
      const secretValue = parsedUrl.searchParams.get("secret");
      if (secretValue) {
        setPairCode(secretValue);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", () => logout(), false);
    const token = localStorage.getItem("accessToken");
    if (!token) {
      window.location.href = "./";
    }
    setTimeout(() => {
      getMFAUrl();
    }, 2000);
  }, []);

  const onPasteCode = async () => {
    const res = await webSocketService.sendMessage("mfaVerifyCode", "c", {
      totp: otp,
    });
    if (res?.includes("failed") || res.includes("invalid")) {
      toast.error("Authentication failed", toastOptions);
    } else {
      AppStore.isAuth = true;
      navigate("../");
    }
  };

  if (!pairCode) {
    return null;
  }

  return (
    <div className="mfac__wrapper">
      <Header
        navigate={navigate}
        onBack={() => {
          logout();
          window.location.href = "./";
        }}
        goBack={false}
      />
      <img src={Logo} alt="logo" className="mfac__logo" />
      <div className="mfac__info">
        <span onClick={() => copy(pairCode)} className="mfac__code">
          {pairCode}
        </span>
        <span className="mfa__desc">
          Use this code to connect your Google Authentificator or Authy app to
          obtain the 2-Step Verification code and enter it below:
        </span>
      </div>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props) => <input {...props} placeholder=" " />}
        inputStyle="mfac__input"
        containerStyle="mfac__input-container"
      />

      <Button
        label="Paste"
        wrapperStyles="mfac__button"
        onPress={() => onPasteCode()}
      />
    </div>
  );
};

export default MfaConnect;
