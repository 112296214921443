import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddGroupModal from "../AddGroupModal/AddGroupModal";
import Button from "../Button/Button";
import DeleteIcon from "../../assets/svgs/deleteIcon.svg";

import "./LimitsItem.scss";
import DeleteGroupModal from "../DeleteGroupModal/DeleteGroupModal";

const LimitsItem = ({ item, index, onEditSuccess }) => {
  const [showAddSchemeModal, setShowAddSchemeModal] = useState(false);
  const [showDeleteSchemeModal, setShowDeleteSchemeModal] = useState(false);
  const isBuiltIn = item?.scheme?.type === "builtIn";
  const navigate = useNavigate();

  return (
    <div
      className={`limits-item__wrapper ${
        index % 2 === 0 ? "limits-item" : "limits-item__dark"
      }`}
    >
      <span
        className={`limits-item__element ${
          isBuiltIn ? "limits-item__element-bold" : ""
        }`}
      >
        {item?.name}
      </span>
      <span className="limits-item__element">{`$${item?.scheme?.daily?.withdrawals}/day - $${item?.scheme?.weekly?.withdrawals}/week`}</span>
      <span className="limits-item__element">{`$${item?.scheme?.daily?.send}/day - $${item?.scheme?.weekly?.send}/week`}</span>
      <Button
        wrapperStyles="limits-item__button"
        labelStyles="limits-item__label"
        label="Edit Limits"
        onPress={() => setShowAddSchemeModal(true)}
      />
      <Button
        wrapperStyles="limits-item__button"
        labelStyles="limits-item__label"
        label="Users"
        onPress={() => navigate(`/usersByLimit/${item?.name}`)}
      />
      {!isBuiltIn && (
        <img
          src={DeleteIcon}
          alt="logo"
          className="limits-item__delete"
          onClick={() => setShowDeleteSchemeModal(true)}
        />
      )}

      {showAddSchemeModal && (
        <AddGroupModal
          showModal={showAddSchemeModal}
          setShowModal={setShowAddSchemeModal}
          isEditing={true}
          onSuccess={onEditSuccess}
          currentScheme={item}
        />
      )}
      {showDeleteSchemeModal && (
        <DeleteGroupModal
          showModal={showDeleteSchemeModal}
          setShowModal={setShowDeleteSchemeModal}
          onSuccess={onEditSuccess}
          scheme={item.name}
        />
      )}
    </div>
  );
};

export default LimitsItem;
