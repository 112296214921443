import React from "react";
import "./Button.scss";
import ButtonArrow from "../../assets/svgs/buttonArrow.svg";
import { PulseLoader } from "react-spinners";

const Button = ({
  label,
  type = "",
  wrapperStyles,
  onPress = () => {},
  disabled = false,
  smaller = false,
  withArrow = false,
  loading = false,
  labelStyles = "",
}) => {
  return (
    <button
      disabled={disabled}
      className={`button__wrapper ${wrapperStyles || ""} ${
        disabled ? "button__disabled" : ""
      } ${smaller ? "button__smaller" : ""} `}
      onClick={() => onPress()}
      type={type}
    >
      <span
        className={`button__label ${
          smaller ? "button__smaller-label" : ""
        } ${labelStyles}`}
      >
        {label}
      </span>
      {withArrow && (
        <img src={ButtonArrow} alt="arrow" className="button__arrow" />
      )}
      {loading && (
        <div className="button__loader">
          <PulseLoader color="white" loading={loading} size={10} />
        </div>
      )}
    </button>
  );
};

export default Button;
