import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import webSocketService from "../../services/WebSocketService";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import DeleteBigIcon from "../../assets/svgs/deleteBigIcon.svg";
import {
  getModalCustomStyles,
  toastOptions,
} from "../../utils/constants/constants";
import Button from "../Button/Button";
import "./DeleteGroupModal.scss";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

const DeleteGroupModal = ({
  showModal = true,
  setShowModal = () => {},
  scheme = "",
  onSuccess = () => {},
}) => {
  const onDelete = async () => {
    const result = await webSocketService.sendMessage(
      "removeRestrictionScheme",
      "c",
      { scheme },
    );

    if (result === "") {
      toast.success("Scheme has been deleted!", toastOptions);
      setShowModal(false);
      onSuccess();
    } else {
      toast.error(capitalizeFirstLetter(result), toastOptions);
    }
  };

  return showModal ? (
    <Modal
      isOpen={showModal}
      style={getModalCustomStyles(230, { width: 450 })}
      ariaHideApp={false}
    >
      <div className="delete-group-modal__wrapper">
        <img
          src={DeleteBigIcon}
          alt="Delete Icon"
          className="delete-group-modal__icon"
        />
        <span className="delete-group-modal__title">Are you sure?</span>
        <span className="delete-group-modal__subtitle">
          {`Do you want to delete “${capitalizeFirstLetter(scheme)}”?`}
        </span>
        <div className="delete-group-modal__buttons">
          <SecondaryButton
            title="No, Cancel"
            wrapperClass="delete-group-modal__button"
            onClick={() => setShowModal(false)}
            labelClass="delete-group-modal__label-no"
          />
          <Button
            wrapperStyles="delete-group-modal__button delete-group-modal__button-yes"
            labelStyles="delete-group-modal__label-yes"
            label="Yes, Delete"
            onPress={onDelete}
          />
        </div>
      </div>
    </Modal>
  ) : null;
};

export default DeleteGroupModal;
