import React from "react";
import "./LimitsLegend.scss";

const LimitsLegend = () => {
  return (
    <div className="legend-limits__wrapper">
      <span className="legend-limits__element">Limit group</span>
      <span className="legend-limits__element">USD Withdrawal </span>
      <span className="legend-limits__element">BTC Send </span>
    </div>
  );
};

export default LimitsLegend;
