import { BTC_ZERO_BALANCE } from "./constants/constants";

const formatCryptoAmount = (value, withoutFormatting = false) => {
  if (withoutFormatting) {
    return value;
  }

  if (
    value === undefined ||
    value === null ||
    value === "" ||
    typeof value === "number"
  ) {
    return "";
  }

  if (value === "0") {
    return value + BTC_ZERO_BALANCE;
  }

  const decimalIndex = value.indexOf(".");
  const digitsAfterDecimal =
    value.length - (decimalIndex === -1 ? value.length - 1 : decimalIndex) - 1;

  if (digitsAfterDecimal === 8) {
    return value;
  }

  if (digitsAfterDecimal > 8) {
    const arrFromValue = value.split(".");
    return `${arrFromValue[0]}.${arrFromValue[1].slice(0, 8)}`;
  }

  const numZerosToAdd = 8 - digitsAfterDecimal;
  const zeros = "0".repeat(numZerosToAdd);
  return value + (decimalIndex === -1 ? "." : "") + zeros;
};

export default formatCryptoAmount;
