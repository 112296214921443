import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Close from "../../assets/svgs/close.svg";
import webSocketService from "../../services/WebSocketService";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import {
  getModalCustomStyles,
  toastOptions,
} from "../../utils/constants/constants";
import Button from "../Button/Button";
import Input from "../Input/Input";
import "./AddGroupModal.scss";

const AddGroupModal = ({
  showModal = true,
  setShowModal = () => {},
  currentScheme = null,
  isEditing = false,
  onSuccess = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const schemeData = {
      name: isEditing ? currentScheme?.name : data.name,
      scheme: {
        daily: { send: data.sendDay, withdrawals: data.withdrawalDay },
        weekly: { send: data.sendWeek, withdrawals: data.withdrawalWeek },
      },
    };

    const result = await webSocketService.sendMessage(
      "setRestrictionScheme",
      "c",
      schemeData,
    );

    if (result === "") {
      toast.success(
        `Scheme has been ${isEditing ? "updated" : "added"}!`,
        toastOptions,
      );
      setShowModal(false);
      onSuccess();
    } else {
      toast.error(capitalizeFirstLetter(result), toastOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isEditing) {
      const { daily, weekly } = currentScheme?.scheme;

      setValue("name", currentScheme?.name);
      setValue("sendDay", daily?.send);
      setValue("withdrawalDay", daily?.withdrawals);
      setValue("sendWeek", weekly?.send);
      setValue("withdrawalWeek", weekly?.withdrawals);
    }
  }, []);

  return showModal ? (
    <Modal
      isOpen={showModal}
      style={getModalCustomStyles()}
      ariaHideApp={false}
    >
      <form
        className="add-group-modal__wrapper"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="add-group-modal__header">
          <span className="add-group-modal__header-label">
            {isEditing ? "Edit Limits" : "Add Group"}
          </span>
          <img
            className="add-group-modal__close"
            src={Close}
            alt="close"
            onClick={() => setShowModal(false)}
          />
        </div>
        <Input
          label="Group Name"
          wrapperStyles="add-group-modal__input__wrapper"
          placeholder="Group Name"
          disabled={isEditing}
          name="name"
          register={register}
          errors={errors}
          inputStyles="add-group-modal__input"
          rules={{
            required: "This field is required",
            pattern: {
              value: /^[a-z]+$/,
              message: `Small letters only`,
            },
          }}
        />

        <span className="add-group-modal__chapter">USD Withdrawal</span>
        <div className="add-group-modal__pair-inputs">
          <Input
            label="Day"
            placeholder="$ 0.00"
            name="withdrawalDay"
            register={register}
            errors={errors}
            inputStyles="add-group-modal__input add-group-modal__input__small"
            wrapperStyles="add-group-modal__input__wrapper"
            labelStyles="add-group-modal__small-label"
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[0-9]+(.[0-9]{1,2})?$/,
                message: `Numbers in the format "XX.XX"`,
              },
            }}
          />
          <Input
            label="Week"
            placeholder="$ 0.00"
            name="withdrawalWeek"
            register={register}
            errors={errors}
            inputStyles="add-group-modal__input add-group-modal__input__small"
            wrapperStyles="add-group-modal__input__wrapper "
            labelStyles="add-group-modal__small-label"
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[0-9]+(.[0-9]{1,2})?$/,
                message: `Numbers in the format "XX.XX"`,
              },
            }}
          />
        </div>

        <span className="add-group-modal__chapter">BTC Send</span>
        <div className="add-group-modal__pair-inputs">
          <Input
            label="Day"
            placeholder="$ 0.00"
            name="sendDay"
            register={register}
            errors={errors}
            inputStyles="add-group-modal__input add-group-modal__input__small"
            wrapperStyles="add-group-modal__input__wrapper"
            labelStyles="add-group-modal__small-label"
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[0-9]+(.[0-9]{1,2})?$/,
                message: `Numbers in the format "XX.XX"`,
              },
            }}
          />
          <Input
            label="Week"
            placeholder="$ 0.00"
            name="sendWeek"
            register={register}
            errors={errors}
            inputStyles="add-group-modal__input add-group-modal__input__small"
            wrapperStyles="add-group-modal__input__wrapper "
            labelStyles="add-group-modal__small-label"
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[0-9]+(.[0-9]{1,2})?$/,
                message: `Numbers in the format "XX.XX"`,
              },
            }}
          />
        </div>

        <Button
          label={isEditing ? "Edit" : "Add"}
          type="submit"
          wrapperStyles="add-group-modal__button"
          loading={loading}
        />
      </form>
    </Modal>
  ) : null;
};

export default AddGroupModal;
