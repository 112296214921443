import React from "react";
import { calculateDecimal } from "../../utils/calculateDecimal";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import parseDate from "../../utils/parseDate";
import "./TotalBalancesItem.scss";

const TotalBalancesItem = ({ item, index }) => {
  const calculateVolume = (data = {}) => {
    const { ETH, ...filteredData } = data;

    const sum = Object.values(filteredData).reduce(
      (accumulator, { value, rate }) =>
        calculateDecimal(
          accumulator,
          calculateDecimal(+value, +rate, "multiply"),
          "add"
        ),
      0
    );

    return cutWithoutRounding(sum, 2);
  };

  const { date, balances = {} } = item;
  const { BTC = {}, USD = {}, LN = {} } = balances;

  const wrapperClass = `total-balances-item__wrapper ${
    index % 2 === 0 ? "total-balances-item" : "total-balances-item__dark"
  }`;

  return (
    <div className={wrapperClass}>
      <span className="total-balances-item__element">{parseDate(date)}</span>
      <span className="total-balances-item__element">
        {`$ ${calculateVolume(balances)}`}
      </span>
      <span className="total-balances-item__element">
        {`${BTC.value || 0} BTC`}
      </span>
      <span className="total-balances-item__element">
        {`$ ${USD.value || 0}`}
      </span>
      <span className="total-balances-item__element">
        {`${LN.value || 0} BTC`}
      </span>
    </div>
  );
};

export default TotalBalancesItem;
