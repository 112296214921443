import React from "react";
import "./BalanceItem.scss";

const BalanceItem = ({
  title = "",
  balance = "",
  isSingleTitle = false,
  onClick = () => {},
}) => {
  return isSingleTitle ? (
    <div
      className="balance-item__wrapper balance-item__smaller"
      onClick={onClick}
    >
      <span className="balance-item__single-title ">{title}</span>
    </div>
  ) : (
    <div className="balance-item__wrapper">
      <span className="balance-item__title">{title}</span>
      <span className="balance-item__balance">{balance}</span>
    </div>
  );
};

export default BalanceItem;
