import React from "react";
import { useNavigate } from "react-router-dom";
import "./VolumeDatailsItem.scss";

const VolumeDatailsItem = ({ item }) => {
  const navigate = useNavigate();
  const { contact, volume } = item;

  return (
    <div className={"volume-details-item__wrapper"}>
      <span className="volume-details-item__element">{`$ ${volume}`}</span>
      <span
        onClick={() => navigate(`/user/${contact.id}`)}
        className="volume-details-item__element volume-details-item__element-bold"
      >
        {contact?.email}
      </span>
    </div>
  );
};

export default VolumeDatailsItem;
