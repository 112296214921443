import { makeAutoObservable } from "mobx";

class AppStoreClass {
  isAuth = false;
  isWSSConnected = false;
  mfaStatus = "";
  homeData = null;
  globalLoading = false;

  constructor() {
    makeAutoObservable(this);
  }
}

const AppStore = new AppStoreClass();
export default AppStore;
