import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { privateRouter, router } from "./navigation/router";
import webSocketService from "./services/WebSocketService";
import { observer } from "mobx-react";
import { configure } from "mobx";
import AppStore from "./store/appStore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

configure({ enforceActions: "never" });

const App = observer(() => {
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      webSocketService.connect(token, () => {});
    }
  }, []);
  return !AppStore.globalLoading ? (
    <>
      <ToastContainer />
      <RouterProvider router={AppStore.isAuth ? privateRouter : router} />
    </>
  ) : null;
});

export default App;
