import React from "react";
import { useNavigate } from "react-router-dom";
import UserType from "../UserType/UserType";
import "./TableItemUser.scss";
import UserIcon from "../../assets/svgs/userIcon.svg";
import GoogleIcon from "../../assets/svgs/googleIcon.svg";
import AppleIcon from "../../assets/svgs/appleIcon.svg";

const TableItemUser = ({ item, index }) => {
  const navigate = useNavigate();
  const renderLoginMethodIcon = (method) => {
    switch (method) {
      case "signinwithapple":
        return AppleIcon;

      case "google":
        return GoogleIcon;

      case "internal":
        return UserIcon;

      default:
        return UserIcon;
    }
  };

  return (
    <div
      className={`table-item-user__wrapper ${
        index % 2 === 0 ? "table-item-user__light" : "table-item-user__dark"
      }`}
      onClick={() => navigate(`/user/${item.userId}`)}
    >
      <span className="table-item-user__element table-item-user__element-small">
        {item.firstName} {item.lastName}
      </span>
      <span className="table-item-user__element table-item-user__element-big">
        {item.email}
      </span>
      <span className="table-item-user__element table-item-user__element-big">
        {item.userId}
      </span>
      <span className="table-item-user__element table-item-user__element-small">
        {item.referral}
      </span>
      <span className="table-item-user__element table-item-user__element-small">
        <UserType
          type={item?.type}
          customClass="table-item-user__element-type"
        />
      </span>
      <span className="table-item-user__element table-item-user__element-small">
        <img src={renderLoginMethodIcon(item?.last?.login)} alt="arrow" />
      </span>
    </div>
  );
};

export default TableItemUser;
