import React, { useEffect, useState } from "react";
import "./Limits.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import webSocketService from "../../services/WebSocketService";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import LimitsLegend from "../../components/LimitsLegend/LimitsLegend";
import LimitsItem from "../../components/LimitsItem/LimitsItem";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";
import AddGroupModal from "../../components/AddGroupModal/AddGroupModal";

const Limits = () => {
  const navigate = useNavigate();

  const [limitsData, setLimitsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddSchemeModal, setShowAddSchemeModal] = useState(false);

  const getLimitsData = async () => {
    const restrictionSchemes = await webSocketService.sendMessage(
      "getRestrictionSchemes",
      "c",
    );
    const schemesInArray = Object.values(restrictionSchemes);
    const filteredSchemes = [
      ...schemesInArray
        .filter((el) => el?.scheme?.type === "builtIn")
        .sort((a, b) => a?.name.localeCompare(b?.name)),
      ...schemesInArray
        .filter((el) => el?.scheme?.type !== "builtIn")
        .sort((a, b) => a?.name.localeCompare(b?.name)),
    ];

    setLimitsData(filteredSchemes);
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getLimitsData();
    }, 2000);
  }, []);

  return !isLoading ? (
    <div className="limits__wrapper">
      <div className="limits__content">
        <Header withLogo navigate={navigate} />

        <div className="limits__top-block">
          <span className="limits__label">User limits</span>
          <SecondaryButton
            title="+ Add Group"
            onClick={() => setShowAddSchemeModal(true)}
          />
        </div>
        <LimitsLegend />
        {limitsData?.map((el, index) => (
          <LimitsItem
            index={index}
            key={el?.name}
            item={el}
            onEditSuccess={getLimitsData}
          />
        ))}
      </div>
      <Footer navigate={navigate} />
      {showAddSchemeModal && (
        <AddGroupModal
          showModal={showAddSchemeModal}
          setShowModal={setShowAddSchemeModal}
          isEditing={false}
          onSuccess={getLimitsData}
        />
      )}
    </div>
  ) : (
    <div className="limits__loader">
      <MoonLoader
        color="#7236CD"
        loading={isLoading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default Limits;
