import React from "react";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import getStatusColor from "../../utils/getStatusColor";

import "./TransactionItem.scss";

const TransactionItem = ({ item, filter }) => {
  const renderAmount = () => {
    if (filter === "sendCashTransfers" || filter === "invoiceTransfers") {
      return `${item?.remittance.currency} ${cutWithoutRounding(
        item?.remittance.amount,
        item?.remittance.currency === "USD" ? 2 : 8,
      )} `;
    }

    return `${item.currency} ${cutWithoutRounding(
      item.amount,
      item.currency === "USD" ? 2 : 8,
    )}`;
  };

  return (
    <div className={`tr-item__wrapper tr-item__dark`}>
      <span className="tr-item__element tr-item__element-bold">
        {capitalizeFirstLetter(item.type)}{" "}
        {item?.method ? ` (${item?.method?.toUpperCase()})` : ""}
      </span>
      <span className="tr-item__element">
        {new Date(item?.created)
          .toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          ?.replaceAll(".", "/")}
      </span>
      <span className="tr-item__element">{renderAmount()}</span>
      <span className="tr-item__element tr-item__element-bigger">
        {item.id}
      </span>
      <span
        className={`tr-item__element tr-item__element-bold ${getStatusColor(
          item.status,
        )}`}
      >
        {capitalizeFirstLetter(item.status)}
      </span>
    </div>
  );
};

export default TransactionItem;
