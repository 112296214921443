import React, { useEffect, useState } from "react";
import "./Login.scss";
import Logo from "../../assets/svgs/logo.svg";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useForm } from "react-hook-form";
import { authenticate } from "../../services/authentication";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const onSubmit = async (data) => {
    setError("");
    authenticate(data.login, data.password, navigate, setError);
  };

  return !isLoading ? (
    <div className="login__wrapper">
      <img src={Logo} alt="logo" className="login__logo" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <span className="login__error">{error}</span>
        <Input
          label="Username"
          placeholder="Username"
          wrapperStyles="login__log"
          name="login"
          register={register}
          errors={errors}
          required={"Login is required"}
        />
        <Input
          label="Password"
          placeholder="Password"
          type="password"
          name="password"
          register={register}
          errors={errors}
          required={"Password is required"}
        />
        <Button label="Login" wrapperStyles="login__button" type="submit" />
      </form>
      {/* <span className="login__or">Or</span>
      <Button label="Sign in with Google" wrapperStyles="login__button" /> */}
    </div>
  ) : null;
};

export default Login;
