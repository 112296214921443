import React from "react";
import "./Header.scss";
import BackArrow from "../../assets/svgs/backArrow.svg";
import Logo from "../../assets/svgs/logo.svg";

const Header = ({
  navigate,
  withLogo = false,
  onBack = () => {},
  goBack = true,
}) => {
  return (
    <div className="header__wrapper">
      <img
        src={BackArrow}
        alt="logo"
        className="header__back"
        onClick={() => {
          onBack();
          if (goBack) {
            navigate(-1);
          }
        }}
      />
      {withLogo && (
        <img
          src={Logo}
          alt="logo"
          className="header__logo"
          onClick={() => navigate("/")}
        />
      )}
    </div>
  );
};

export default Header;
