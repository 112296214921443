import { calculateDecimal } from "../utils/calculateDecimal";
import { cutWithoutRounding } from "../utils/cutWithoutRounding";

const useTransactionsStats = (transactions = [], transfersData = []) => {
  const deposits = transactions.filter((el) => el.type === "deposit");

  const depositsBTC = deposits
    .filter(
      (el) =>
        el.currency === "BTC" &&
        (el.custodial === "sfox" || el.custodial === ""),
    )
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const depositsUSD = deposits
    .filter(
      (el) =>
        el.currency === "USD" &&
        (el.custodial === "sfox" || el.custodial === ""),
    )
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const withdrawals = transactions.filter((el) => el.type === "withdraw");

  const withdrawalsBTC = withdrawals
    .filter(
      (el) =>
        (el.custodial === "sfox" || el.custodial === "") &&
        el.currency === "BTC",
    )
    .reduce((sum, el) => {
      return calculateDecimal(sum, +-el.amount, "add");
    }, 0);

  const withdrawalsUSD = withdrawals
    .filter(
      (el) =>
        (el.custodial === "sfox" || el.custodial === "") &&
        el.currency === "USD",
    )
    .reduce((sum, el) => {
      return calculateDecimal(sum, +-el.amount, "add");
    }, 0);

  const trade = transactions.filter((el) => el.type === "buy");

  const tradeBTC = trade
    .filter((el) => el.currency === "BTC")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const tradeUSD = trade
    .filter((el) => el.currency === "USD")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const fees = transactions.filter((el) => el.type === "fee");

  const feesBTC = fees.reduce((sum, el) => {
    return calculateDecimal(sum, +el.amount, "add");
  }, 0);

  const withdrawalsBTCFees = withdrawals
    .filter(
      (el) =>
        (el.custodial === "sfox" || el.custodial === "") &&
        el.currency === "BTC",
    )
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.fee, "add");
    }, 0);

  const feesSum = calculateDecimal(feesBTC, withdrawalsBTCFees, "add");

  const withdrawalsUSDFees = withdrawals
    .filter(
      (el) =>
        (el.custodial === "sfox" || el.custodial === "") &&
        el.currency === "USD",
    )
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.fee, "add");
    }, 0);

  const market = transactions?.filter(
    (el) => el.type === "charge" && el.module === "market",
  );

  const marketBTC = market
    .filter((el) => el.currency === "BTC")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +-el.amount, "add");
    }, 0);

  const marketUSD = market
    .filter((el) => el.currency === "USD")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +-el.amount, "add");
    }, 0);

  const rewards = transactions?.filter(
    (el) => el.type === "credit" && el.module === "referral",
  );

  const rewardsBTC = rewards
    .filter((el) => el.currency === "BTC")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const rewardsUSD = rewards
    .filter((el) => el.currency === "USD")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const credit = transactions?.filter(
    (el) => el.type === "credit" && el.module !== "referral",
  );

  const creditBTC = credit
    .filter((el) => el.currency === "BTC")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const creditUSD = credit
    .filter((el) => el.currency === "USD")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.amount, "add");
    }, 0);

  const payOut = transactions?.filter(
    (el) => el.type === "payOut" && el.module === "lightning",
  );

  const payOutBTC = payOut.reduce((sum, el) => {
    return calculateDecimal(sum, +-el.amount, "add");
  }, 0);

  const payIn = transactions?.filter(
    (el) => el.type === "payIn" && el.module === "lightning",
  );

  const payInBTC = payIn.reduce((sum, el) => {
    return calculateDecimal(sum, +el.amount, "add");
  }, 0);

  const onChainReceive = transactions?.filter(
    (el) => el.type === "deposit" && el.module === "lightning",
  );

  const onChainReceiveBTC = onChainReceive.reduce((sum, el) => {
    return calculateDecimal(sum, +el.amount, "add");
  }, 0);

  const onChainSend = transactions?.filter(
    (el) => el.type === "withdraw" && el.module === "lightning",
  );

  const onChainSendBTC = onChainSend.reduce((sum, el) => {
    return calculateDecimal(sum, +-el.amount, "add");
  }, 0);

  const sendCashTransfers = transfersData?.filter((el) => el.type === "getbit");

  const sendCashTransfersBTC = sendCashTransfers
    ?.filter((el) => el.remittance.currency === "BTC")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.remittance.amount, "add");
    }, 0);
  const sendCashTransfersUSD = sendCashTransfers
    ?.filter((el) => el.remittance.currency === "USD")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.remittance.amount, "add");
    }, 0);

  const invoiceTransfers = transfersData?.filter((el) => el.type === "invoice");

  const invoiceTransfersBTC = invoiceTransfers
    ?.filter((el) => el.remittance.currency === "BTC")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.remittance.amount, "add");
    }, 0);
  const invoiceTransfersUSD = invoiceTransfers
    ?.filter((el) => el.remittance.currency === "USD")
    .reduce((sum, el) => {
      return calculateDecimal(sum, +el.remittance.amount, "add");
    }, 0);

  const keys = [
    "Deposits",
    "Withdrawals",
    "Trades",
    "On Chain Sends",
    "On Chain Receives",
    "Lightning Sends",
    "Lightning Receives",
    "Send Cash Transfers",
    "Invoice Transfers",
    "Credit",
    "Market",
    "Rewards",
    "Fees",
  ];

  return {
    depositsBTC: cutWithoutRounding(depositsBTC, 8),
    depositsUSD: cutWithoutRounding(depositsUSD, 2),
    withdrawalsBTC: cutWithoutRounding(withdrawalsBTC, 8),
    withdrawalsUSD: cutWithoutRounding(withdrawalsUSD, 2),
    tradeBTC: cutWithoutRounding(tradeBTC, 8),
    tradeUSD: cutWithoutRounding(tradeUSD, 2),
    onChainSendBTC: cutWithoutRounding(onChainSendBTC, 8),
    onChainReceiveBTC: cutWithoutRounding(onChainReceiveBTC, 8),
    payOutBTC: cutWithoutRounding(payOutBTC, 8),
    payInBTC: cutWithoutRounding(payInBTC, 8),
    creditBTC: cutWithoutRounding(creditBTC, 8),
    creditUSD: cutWithoutRounding(creditUSD, 2),
    marketBTC: cutWithoutRounding(marketBTC, 8),
    marketUSD: cutWithoutRounding(marketUSD, 2),
    rewardsBTC: cutWithoutRounding(rewardsBTC, 8),
    rewardsUSD: cutWithoutRounding(rewardsUSD, 2),
    feesSum: cutWithoutRounding(feesSum, 8),
    sendCashTransfersBTC: cutWithoutRounding(sendCashTransfersBTC, 8),
    sendCashTransfersUSD: cutWithoutRounding(sendCashTransfersUSD, 2),
    invoiceTransfersBTC: cutWithoutRounding(invoiceTransfersBTC, 8),
    invoiceTransfersUSD: cutWithoutRounding(invoiceTransfersUSD, 2),
    withdrawalsUSDFees: cutWithoutRounding(withdrawalsUSDFees, 2),
    keys,
  };
};

export default useTransactionsStats;
