import React from "react";
import { useNavigate } from "react-router-dom";
import { isSafari } from "../../utils/isSafari";
import "./UserInfoItem.scss";

const UserInfoItem = ({
  title = "",
  value = "",
  isSingleTitle = false,
  wrapperClass = "",
  itemClass = "",
  isMultipleValues,
  values = [],
  hideFunc = null,
  onPress = () => {},
  bigContainer = false,
}) => {
  const navigate = useNavigate();

  return isSingleTitle ? (
    <div
      onClick={() => {
        onPress();
      }}
      className="user-info-item__wrapper user-info-item__smaller"
    >
      <span className={`user-info-item__single-title ${isSafari() ? "user-info-item__single-title-safari" : ""}`}>
        {title}
      </span>
    </div>
  ) : (
    <div
      className={`user-info-item__wrapper ${wrapperClass} ${
        isMultipleValues && bigContainer ? "user-info-item__multi-box" : ""
      }`}
    >
      <span
        className={`user-info-item__title ${
          isMultipleValues ? "user-info-item__title-multi" : ""
        }`}
      >
        {title}
      </span>

      {isMultipleValues ? (
        <div className={`user-info-item__multi-wrapper ${itemClass}`}>
          {values?.map((el) => (
            <span
              onClick={() => navigate(`/user/${el.userId}`)}
              className="user-info-item__multi-value"
              key={el.userId + el.email}
            >
              {el?.email}
            </span>
          ))}
        </div>
      ) : (
        <span
          onClick={() => {
            if (hideFunc) {
              hideFunc();
            }
          }}
          className="user-info-item__balance"
        >
          {value}
        </span>
      )}
    </div>
  );
};

export default UserInfoItem;
