import React from "react";
import "./SecondaryButton.scss";

const SecondaryButton = ({
  title = "",
  onClick = () => {},
  wrapperClass = "",
  labelClass = "",
}) => {
  return (
    <div
      className={`secondary-btn__wrapper secondary-btn__smaller ${wrapperClass}`}
      onClick={onClick}
    >
      <span className={`secondary-btn__single-title ${labelClass}`}>
        {title}
      </span>
    </div>
  );
};

export default SecondaryButton;
