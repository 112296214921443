import React, { useEffect, useState } from "react";
import "./UsersByLimits.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import webSocketService from "../../services/WebSocketService";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import UserItemLimits from "../../components/UserItemLimits/UserItemLimits";
import UsersByLimitLegend from "../../components/UsersByLimitLegend/UsersByLimitLegend";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

const UsersByLimits = () => {
  const navigate = useNavigate();
  const { limit } = useParams();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitsData, setLimitsData] = useState([]);

  const findUsers = async () => {
    const usersData = await webSocketService.sendMessage("findUsers", "c", {
      restrictions: limit,
    });

    setUsers(usersData);
  };
  const getLimitsData = async () => {
    const restrictionSchemes = await webSocketService.sendMessage(
      "getRestrictionSchemes",
      "c",
    );
    const parsedSchemes = Object.values(restrictionSchemes)?.map((el) => ({
      value: el?.name,
      label: el?.name,
    }));

    setLimitsData(parsedSchemes);
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      findUsers();
      getLimitsData();
    }, 2000);
  }, []);

  return !isLoading ? (
    <div className="limits__wrapper">
      <div className="limits__content">
        <Header withLogo navigate={navigate} />

        <div className="limits__top-block">
          <span className="limits__label">
            {capitalizeFirstLetter(limit) + " Limit Users"}
          </span>
        </div>
        {users?.length > 0 ? (
          <UsersByLimitLegend />
        ) : (
          <span>Scheme is empty...</span>
        )}
        {users?.map((el, index) => (
          <UserItemLimits
            index={index}
            key={el?.userId}
            item={el}
            onChangeSuccess={findUsers}
            limitsData={limitsData}
          />
        ))}
      </div>
      <Footer navigate={navigate} />
    </div>
  ) : (
    <div className="limits__loader">
      <MoonLoader
        color="#7236CD"
        loading={isLoading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default UsersByLimits;
