import React, { useState } from "react";
import webSocketService from "../../services/WebSocketService";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import Button from "../Button/Button";
import ChangeGroupModal from "../ChangeGroupModal/ChangeGroupModal";

import "./UserItemLimits.scss";

const UserItemLimits = ({ item, index, onChangeSuccess, limitsData }) => {
  const [showChangeGroupModal, setShowChangeGroupModal] = useState(false);
  const [limits, setLimits] = useState(null);

  const onGetLimits = async () => {
    const user = await webSocketService.sendMessage("getUser", "c", {
      userId: item.userId,
    });
    setLimits(user?.restrictions?.limits);
  };

  const getLimitsData = (coin, limitsData) => {
    if (coin === "BTC") {
      return limitsData?.crypto;
    }
    if (coin === "USD") {
      return limitsData?.fiat;
    }
  };

  const renderLimitsField = (coin) => {
    if (!limits) {
      return (
        <span
          className="limits-user-item__element limits-user-item__element-clickable"
          onClick={onGetLimits}
        >
          Show Limits
        </span>
      );
    }
    const limitsData = getLimitsData(coin, limits);

    return (
      <span className="limits-user-item__element">
        <span>{`$${cutWithoutRounding(limitsData.daily, 2)}/day`}</span>
        <span>{`$${cutWithoutRounding(limitsData.weekly, 2)}/week`}</span>
      </span>
    );
  };
  return (
    <div
      className={`limits-user-item__wrapper ${
        index % 2 === 0 ? "limits-user-item" : "limits-user-item__dark"
      }`}
    >
      <span className="limits-user-item__element">{`${item?.firstName} ${item?.lastName}`}</span>
      <span className="limits-user-item__element">{item?.email}</span>
      <span className="limits-user-item__element">{item?.userId}</span>
      {renderLimitsField("USD")}
      {renderLimitsField("BTC")}
      <Button
        wrapperStyles="limits-user-item__button"
        labelStyles="limits-user-item__label"
        label="Change Group"
        onPress={() => setShowChangeGroupModal(true)}
      />

      {showChangeGroupModal && (
        <ChangeGroupModal
          showModal={showChangeGroupModal}
          setShowModal={setShowChangeGroupModal}
          isEditing={true}
          onSuccess={onChangeSuccess}
          currentScheme={item}
          limitsData={limitsData}
          userId={item?.userId}
        />
      )}
    </div>
  );
};

export default UserItemLimits;
