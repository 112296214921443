import Decimal from "decimal.js";

export const calculateDecimal = (value1, value2, operation) => {
  if (
    (!value1 && value1 !== 0) ||
    (!value2 && value2 !== 0 && operation !== "round") ||
    !operation
  ) {
    return 0;
  }
  const x1 = new Decimal(typeof value1 === "string" ? value1 : String(value1));
  const x2 = value2
    ? new Decimal(typeof value2 === "string" ? value2 : String(value2))
    : 0;

  switch (operation) {
    case "add": {
      return x1.add(x2).toString();
    }
    case "minus": {
      return x1.minus(x2).toString();
    }
    case "divide": {
      return x1.dividedBy(x2).toString();
    }

    case "multiply": {
      return x1.mul(x2).toString();
    }

    default:
      return 0;
  }
};
