import { useState } from "react";
import webSocketService from "../services/WebSocketService";

const useUserData = (userId = "") => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [referredData, setReferredData] = useState(null);
  const [transactionsData, setTransactionsData] = useState([]);
  const [transfersData, setTransfersData] = useState([]);
  const [hideDocNumber, setHideDocNumber] = useState(true);
  const [referrer, setReferrer] = useState(null);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [showChangeGroupModal, setShowChangeGroupModal] = useState(false);
  const [limitsData, setLimitsData] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [tokensData, setTokensData] = useState([]);

  const limits = userData?.restrictions?.limits;

  const onShowMore = () => {
    const currentLength = tokensData?.length;

    setTokensData((prev) => [
      ...prev,
      ...tokens?.slice(currentLength, currentLength + 10),
    ]);
  };

  const onShowLess = () => setTokensData(tokens?.slice(0, 5));

  const getUserData = async () => {
    setTimeout(async () => {
      const user = await webSocketService.sendMessage("getUser", "c", {
        userId,
      });

      const referred = await webSocketService.sendMessage("findUsers", "c", {
        referral: user?.referral?.code,
      });

      const referrerData = await webSocketService.sendMessage("getUser", "c", {
        userId: user?.referral?.referrer,
      });

      const transactions = await webSocketService.sendMessage(
        "getTransactions",
        "c",
        {
          userId,
        },
      );
      const finalizedTransactions = transactions.filter(
        (el) => el.status === "finalized",
      );

      const transfers = await webSocketService.sendMessage(
        "getTransfers",
        "c",
        {
          userId,
        },
      );
      const finalizedTransfers = transfers?.filter(
        (el) => el.status === "finalized",
      );

      const restrictionSchemes = await webSocketService.sendMessage(
        "getRestrictionSchemes",
        "c",
      );
      const parsedSchemes = Object.values(restrictionSchemes)?.map((el) => ({
        value: el?.name,
        label: el?.name,
      }));

      const tokens = await webSocketService.sendMessage("getTokens", "c", {
        userId,
      });
      setTokens(tokens);
      setTokensData(tokens?.slice(0, 5));
      setLimitsData(parsedSchemes);
      setReferrer(referrerData);
      setTransactionsData(finalizedTransactions);
      setTransfersData(finalizedTransfers);
      setReferredData(referred);
      setUserData(user);
      setLoading(false);
    }, 2000);
  };

  return {
    getUserData,
    loading,
    referredData,
    transactionsData,
    hideDocNumber,
    referrer,
    showCreditModal,
    userData,
    setShowCreditModal,
    setHideDocNumber,
    transfersData,
    setShowChangeGroupModal,
    showChangeGroupModal,
    limitsData,
    limits,
    tokensData,
    onShowMore,
    onShowLess,
  };
};
export default useUserData;
