import React from "react";
import "./TableLegendUsers.scss";

const TableLegendUsers = () => {
  return (
    <div className="legend-users__wrapper">
      <span className="legend-users__element legend-users__element-small">
        Full name
      </span>
      <span className="legend-users__element legend-users__element-big">
        E-mail
      </span>
      <span className="legend-users__element legend-users__element-big">
        User ID
      </span>
      <span className="legend-users__element legend-users__element-small">
        Referral code
      </span>
      <span className="legend-users__element legend-users__element-medium">
        Account type
      </span>
    </div>
  );
};

export default TableLegendUsers;
