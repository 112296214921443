import React from "react";
import "./CustomSelect.scss";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { customStylesSelect } from "../../utils/constants/constants";

const CustomSelect = ({
  label = "",
  placeholder = "",
  wrapperStyles = "",
  name = "",
  errors = {},
  control,
  options = [],
  rules = {},
  onSelect = () => {},
}) => {
  return (
    <div className={`custom-select__wrapper ${wrapperStyles || ""}`}>
      <span className="custom-select__label">{label}</span>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            styles={customStylesSelect}
            options={options}
            placeholder={placeholder}
            getOptionValue={(option) => option.value}
            onChange={(selectedOption) => {
              field.onChange(selectedOption.value);
              onSelect(selectedOption.value);
            }}
            value={options.find((option) => option.value === field.value)}
          />
        )}
      />

      <span className="custom-select__error">{errors?.[name]?.message}</span>
    </div>
  );
};

export default CustomSelect;
