import React from "react";
import "./TotalBalancesLegend.scss";

const TotalBalancesLegend = () => {
  return (
    <div className="legend-total-balances__wrapper">
      <span className="legend-total-balances__element">Date</span>
      <span className="legend-total-balances__element">Volume</span>
      <span className="legend-total-balances__element">BTC Wallet</span>
      <span className="legend-total-balances__element">USD Wallet</span>
      <span className="legend-total-balances__element">LN Wallet</span>
    </div>
  );
};

export default TotalBalancesLegend;
