import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import webSocketService from "../../services/WebSocketService";
import StatItem from "../../components/StatItem/StatItem";
import Users from "../../components/Users/Users";
import { MoonLoader } from "react-spinners";
import AppStore from "../../store/appStore";
import { loaderOverride } from "../../utils/constants/constants";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState(null);
  const [statistic, setStatistic] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      getHomeData();
    }, 2000);
  }, []);

  const getHomeData = async () => {
    const balancesData = await webSocketService.sendMessage(
      "getCorporate",
      "c",
    );
    const statsData = await webSocketService.sendMessage("getStatistic", "c", {
      type: "general",
    });

    AppStore.homeData = { balancesData, statsData };
    setStatistic(statsData);
    setBalances(balancesData);
    setLoading(false);
  };

  return !loading ? (
    <div className="dashboard__wrapper">
      <HomeHeader balances={balances} />
      <div className="dashboard__stats">
        <StatItem
          title="Total Users"
          value={statistic?.users?.count}
          onClickStatistics={() => navigate(`/totals/balances`)}
        />
        <StatItem
          title="Total Payments"
          value={statistic?.transactions?.count}
          onClickStatistics={() => navigate(`/totals/transactions`)}
        />
      </div>
      <Users navigate={navigate} />
    </div>
  ) : (
    <div className="dashboard__loader">
      <MoonLoader
        color="#7236CD"
        loading={loading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default Dashboard;
