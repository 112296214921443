import React, { useEffect, useState } from "react";
import "./Totals.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import webSocketService from "../../services/WebSocketService";
import Select from "react-select";
import {
  startOfMonth,
  endOfMonth,
  subDays,
  subMonths,
  formatISO,
} from "date-fns";

import { MoonLoader } from "react-spinners";
import {
  customStylesSelect,
  loaderOverride,
  totalsRanges,
} from "../../utils/constants/constants";
import Footer from "../../components/Footer/Footer";
import TotalBalancesLegend from "../../components/TotalBalancesLegend/TotalBalancesLegend";
import TotalTransactionsLegend from "../../components/TotalTransactionsLegend/TotalTransactionsLegend";
import TotalTransactionsItem from "../../components/TotalTransactionsItem/TotalTransactionsItem";
import TotalBalancesItem from "../../components/TotalBalancesItem/TotalBalancesItem";
import TotalRangePicker from "../../components/TotalRangePicker/TotalRangePicker";
import { getTotalVolumeTransactions } from "../../utils/getTotalVolumeTransactions";

const Totals = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  const isBalances = type === "balances";
  const filterTypeSaved = localStorage.getItem(
    isBalances ? "balancesFilterType" : "transactionsFilterType",
  );
  const fromSaved = localStorage.getItem(
    isBalances ? "balancesFrom" : "transactionsFrom",
  );
  const toSaved = localStorage.getItem(
    isBalances ? "balancesTo" : "transactionsTo",
  );
  const savedSelectorValue =
    localStorage.getItem(
      isBalances ? "balancesFilterType" : "transactionsFilterType",
    ) || "past7";

  const isTransactions = type === "transactions";
  const [totalsData, setTotalsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());
  const [selectorValue, setSelectorValue] = useState(savedSelectorValue);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showRangePicker, setShowRangePicker] = useState(false);

  const getDateRange = (value) => {
    const today = new Date();
    let startDate, endDate;

    switch (value) {
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        break;
      case "lastMonth":
        const lastMonthDate = subMonths(today, 1);
        startDate = startOfMonth(lastMonthDate);
        endDate = endOfMonth(lastMonthDate);
        break;
      case "past7":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "past30":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "past90":
        startDate = subDays(today, 90);
        endDate = today;
        break;
      case "customized":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setFromDate(startDate);
    setToDate(endDate);
    return { startDate, endDate };
  };

  const getTotalsData = async (from, to) => {
    let body = {
      type,
      from: formatISO(new Date(from)),
      to: formatISO(new Date(to)),
    };

    if (type === "transactions") {
      body = { ...body, groupBy: "date" };
    }

    const totals = await webSocketService.sendMessage(
      "getStatistic",
      "c",
      body,
    );

    if (typeof totals === "string") {
      setTotalsData([]);
    } else {
      const sortedData = totals.sort(
        (a, b) => new Date(b.date) - new Date(a.date),
      );
      setTotalsData(sortedData);
      localStorage.setItem(
        isBalances ? "balancesFilterType" : "transactionsFilterType",
        selectorValue,
      );
      localStorage.setItem(
        isBalances ? "balancesFrom" : "transactionsFrom",
        from,
      );
      localStorage.setItem(isBalances ? "balancesTo" : "transactionsTo", to);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (filterTypeSaved && filterTypeSaved !== "null") {
      setSelectorValue(filterTypeSaved);
      getTotalsData(fromSaved, toSaved);
    } else if (filterTypeSaved === "null" && fromSaved) {
      setSelectorValue("customized");
      getTotalsData(fromSaved, toSaved);
    } else {
      getTotalsData(fromDate, toDate);
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (selectorValue === "customized") {
        setSelectorValue("customized");
        return setShowRangePicker(true);
      }

      if (selectorValue) {
        const range = getDateRange(selectorValue);
        getTotalsData(range.startDate, range.endDate);
      }
    }
  }, [selectorValue]);

  const renderTitle = () => {
    switch (true) {
      case isBalances:
        return "Total users' balance";
      case isTransactions:
        return "Total volume of all transactions";
      default:
        return "Total users' balance";
    }
  };

  return !isLoading ? (
    <div className="totals__wrapper">
      <div className="totals__content">
        <Header withLogo navigate={navigate} />

        <div className="totals__top-block">
          <span className="totals__label">{renderTitle()}</span>
          {isTransactions && (
            <span className="totals__volume">
              Total Volume:
              <span className="totals__volume totals__bold">
                {` $ ${getTotalVolumeTransactions(totalsData)}`}
              </span>
            </span>
          )}
        </div>

        <div className="totals__selector-wrapper">
          <span className="totals__selector-label">Period</span>
          <Select
            styles={customStylesSelect}
            options={totalsRanges}
            placeholder={"placeholder"}
            getOptionValue={(option) => option.value}
            onChange={(option) => {
              if (
                option.value === "customized" &&
                selectorValue === "customized"
              ) {
                setShowRangePicker(true);
              }

              setSelectorValue(option.value);
            }}
            value={totalsRanges.find(
              (option) => option.value === selectorValue,
            )}
          />
        </div>
        {isBalances ? <TotalBalancesLegend /> : <TotalTransactionsLegend />}
        {totalsData?.map((el, index) =>
          isBalances ? (
            <TotalBalancesItem key={index} index={index} item={el} />
          ) : (
            <TotalTransactionsItem
              key={el?.volume + index}
              index={index}
              item={el}
            />
          ),
        )}
      </div>
      <Footer navigate={navigate} />
      {showRangePicker && (
        <TotalRangePicker
          showModal={showRangePicker}
          setShowModal={setShowRangePicker}
          getData={getTotalsData}
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    </div>
  ) : (
    <div className="totals__loader">
      <MoonLoader
        color="#7236CD"
        loading={isLoading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default Totals;
