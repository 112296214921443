export const getTypeBGColor = (type, initalClass) => {
  switch (type) {
    case "corporation":
      return `${initalClass}-green`;
    case "individual":
      return `${initalClass}-yellow`;
    default:
      return "";
  }
};

export const renderTypeTitle = (type) => {
  switch (type) {
    case "corporation":
      return "Business";
    case "individual":
      return "Individual";
    default:
      return "";
  }
};
