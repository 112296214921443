import React from "react";
import { useNavigate } from "react-router-dom";
import parseDate from "../../utils/parseDate";
import Button from "../Button/Button";
import "./TotalTransactionsItem.scss";

const TotalTransactionsItem = ({ item, index }) => {
  const navigate = useNavigate();
  const { date, volume } = item;

  const wrapperClass = `total-transactions-item__wrapper ${
    index % 2 === 0
      ? "total-transactions-item"
      : "total-transactions-item__dark"
  }`;

  return (
    <div className={wrapperClass}>
      <span className="total-transactions-item__element">
        {parseDate(date)}
      </span>
      <span className="total-transactions-item__element">{`$ ${volume}`}</span>
      <Button
        wrapperStyles="total-transactions-item__button"
        labelStyles="total-transactions-item__label"
        label="Details"
        onPress={() => navigate(`/volumeDetails/${date}`)}
      />
    </div>
  );
};

export default TotalTransactionsItem;
