import React from "react";
import "./UsersByLimitLegend.scss";

const UsersByLimitLegend = () => {
  return (
    <div className="legend-users-limits__wrapper">
      <span className="legend-users-limits__element">Full name</span>
      <span className="legend-users-limits__element">E-mail</span>
      <span className="legend-users-limits__element">User ID</span>
      <span className="legend-users-limits__element">USD Withdrawal</span>
      <span className="legend-users-limits__element">BTC Send</span>
    </div>
  );
};

export default UsersByLimitLegend;
