import React from "react";

import TableItemUser from "../TableItemUser/TableItemUser";
import TableLegendUsers from "../TableLegendUsers/TableLegendUsers";
import "./UsersTable.scss";

const UsersTable = ({ users = [] }) => {
  return (
    <div className="users-table__container">
      <div className="users-table__title-container">
        <span className="users-table__title">Search results:</span>
        <span>{`${users?.length} results`}</span>
      </div>

      {users?.length > 0 && (
        <>
          <TableLegendUsers />
          {users &&
            users?.map((el, index) => (
              <TableItemUser item={el} key={index} index={index} />
            ))}
        </>
      )}
    </div>
  );
};

export default UsersTable;
