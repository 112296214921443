import React from "react";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import Input from "../Input/Input";
import "./UserFilters.scss";

const UserFilters = ({ onSubmit = () => {}, setUsers = () => {} }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <div className="user-filters__wrapper">
      <span className="user-filters__title">User filtres:</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="user-filters__inputs">
          <Input
            label="First name"
            placeholder="Enter first name"
            wrapperStyles="user-filters__input"
            name="firstName"
            register={register}
            errors={errors}
          />
          <Input
            label="User ID"
            placeholder="Enter user ID"
            wrapperStyles="user-filters__input"
            name="userId"
            register={register}
            errors={errors}
          />
          <Input
            label="E-mail"
            placeholder="Enter e-mail"
            wrapperStyles="user-filters__input"
            name="email"
            register={register}
            errors={errors}
          />
        </div>
        <div className="user-filters__inputs">
          <Input
            label="Last name"
            placeholder="Enter last name"
            wrapperStyles="user-filters__input"
            name="lastName"
            register={register}
            errors={errors}
          />
          <Input
            label="Transaction ID"
            placeholder="Enter transaction ID"
            wrapperStyles="user-filters__input"
            name="txId"
            register={register}
            errors={errors}
          />

          <Input
            label="Referral code"
            placeholder="Enter referral code"
            wrapperStyles="user-filters__input"
            name="referral"
            register={register}
            errors={errors}
          />
        </div>
        <div className="user-filters__buttons">
          <Button
            label="Run"
            type="submit"
            wrapperStyles="user-filters__button"
          />
          <Button
            label="Reset filters"
            type="button"
            wrapperStyles="user-filters__button-reset"
            labelStyles="user-filters__button-label"
            onPress={() => {
              reset();
              setUsers([]);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default UserFilters;
