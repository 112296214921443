import React from "react";
import "./StatItem.scss";
import Stats from "../../assets/svgs/stats.svg";

const StatItem = ({ title = "", value = "", onClickStatistics = () => {} }) => {
  return (
    <div className="stat-item__wrapper">
      <span className="stat-item__title">{title}</span>
      <span className="stat-item__balance">{value}</span>
      <div className="stat-item__button" onClick={onClickStatistics}>
        <img src={Stats} alt="logout" />
        <span className="stat-item__button__label">Statistics</span>
      </div>
    </div>
  );
};

export default StatItem;
