import React from "react";
import "./VolumeDetailsLegend.scss";

const VolumeDetailsLegend = () => {
  return (
    <div className="volume-details__wrapper">
      <span className="volume-details__element">Volume</span>
      <span className="volume-details__element">User</span>
    </div>
  );
};

export default VolumeDetailsLegend;
