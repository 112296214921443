import React from "react";
import "./Footer.scss";
import LogoGray from "../../assets/svgs/logoGray.svg";

const Footer = ({ navigate }) => {
  return (
    <div className="footer__wrapper">
      <img
        src={LogoGray}
        alt="logo"
        className="footer__logo"
        onClick={() => navigate("/")}
      />
      <span className="footer__title">Rhino. Admins panel.</span>
    </div>
  );
};

export default Footer;
