import React from "react";
import { useNavigate } from "react-router-dom";
import useTransactionsStats from "../../hooks/useTransactionsStats";
import { transactionsFiltersList } from "../../utils/constants/constants";
import "./TransactionsStats.scss";

const TransactionsStats = ({
  transactions = [],
  transfersData = [],
  userId = "",
}) => {
  const navigate = useNavigate();

  const {
    depositsBTC,
    depositsUSD,
    withdrawalsBTC,
    withdrawalsUSD,
    tradeBTC,
    tradeUSD,
    onChainSendBTC,
    onChainReceiveBTC,
    payOutBTC,
    payInBTC,
    creditBTC,
    creditUSD,
    marketBTC,
    marketUSD,
    rewardsBTC,
    rewardsUSD,
    feesSum,
    sendCashTransfersBTC,
    sendCashTransfersUSD,
    invoiceTransfersBTC,
    invoiceTransfersUSD,
    withdrawalsUSDFees,
    keys,
  } = useTransactionsStats(transactions, transfersData);

  const values = [
    `BTC ${depositsBTC} / $ ${depositsUSD}`,
    `BTC ${withdrawalsBTC} / $ ${withdrawalsUSD}`,
    `BTC ${tradeBTC} / $${tradeUSD}`,
    `BTC ${onChainSendBTC}`,
    `BTC ${onChainReceiveBTC}`,
    `BTC ${payOutBTC}`,
    `BTC ${payInBTC}`,
    `BTC ${sendCashTransfersBTC} / $ ${sendCashTransfersUSD}`,
    `BTC ${invoiceTransfersBTC} / $ ${invoiceTransfersUSD}`,
    `BTC ${creditBTC} / $ ${creditUSD}`,
    `BTC ${marketBTC} / $ ${marketUSD}`,
    `BTC ${rewardsBTC} / $${rewardsUSD}`,
    `BTC ${feesSum} / $${withdrawalsUSDFees}`,
  ];

  const onClickItem = (index) => {
    navigate(
      `/transactions/${userId}/${
        transactionsFiltersList?.find((el) => el.name === keys[index]).value
      }`,
    );
  };

  return (
    <div className="tr-stats__container">
      {Array(keys.length)
        .fill(0)
        .map((_, index) => (
          <div
            className="tr-stats__box"
            onClick={() => onClickItem(index)}
            key={keys[index]}
          >
            <span className="tr-stats__key">{keys[index]}</span>
            <span className="tr-stats__element">{values[index]}</span>
          </div>
        ))}
    </div>
  );
};

export default TransactionsStats;
