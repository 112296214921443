import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { DatePicker } from "rsuite";
import "rsuite/DatePicker/styles/index.css";

import Close from "../../assets/svgs/close.svg";
import Button from "../Button/Button";
import {
  getModalCustomStyles,
  pickerStyle,
} from "../../utils/constants/constants";
import "./TotalRangePicker.scss";

const TotalRangePicker = ({
  showModal = true,
  setShowModal = () => {},
  getData = () => {},
  setFromDate,
  setToDate,
  toDate,
  fromDate,
}) => {
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    getData(fromDate, toDate);
    setShowModal(false);
  };

  const disableAfterToday = (date) => {
    const today = new Date();
    const minDate = new Date(2024, 0, 1);
    return date > today || date < minDate;
  };

  useEffect(() => {}, []);

  return showModal ? (
    <Modal
      isOpen={showModal}
      style={getModalCustomStyles(230, { overflow: "visible", width: 450 })}
      ariaHideApp={false}
    >
      <form className="total-picker__wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div className="total-picker__header">
          <span className="total-picker__header-label">
            Select a Time Range
          </span>
          <img
            className="total-picker__close"
            src={Close}
            alt="close"
            onClick={() => setShowModal(false)}
          />
        </div>
        <span className="total-picker__subtitle">
          Select your time range within 12 months.
        </span>
        <div className="total-picker__inputs">
          <div className="total-picker__input">
            <span className="total-picker__label">Start time</span>
            <DatePicker
              oneTap
              format="MM-dd-yyyy"
              style={{ width: 200 }}
              value={fromDate}
              onChange={(date) => setFromDate(new Date(date))}
              disabledDate={disableAfterToday}
              size="lg"
              menuStyle={pickerStyle}
              cleanable={false}
            />
          </div>
          <span className="total-picker__to">To</span>
          <div className="total-picker__input">
            <span className="total-picker__label">End time</span>
            <DatePicker
              oneTap
              format="MM-dd-yyyy"
              style={{ width: 200 }}
              value={toDate}
              onChange={(date) => setToDate(new Date(date))}
              disabledDate={disableAfterToday}
              size="lg"
              menuStyle={pickerStyle}
              cleanable={false}
            />
          </div>
        </div>
        <Button
          label="Continue"
          type="submit"
          wrapperStyles="total-picker__button"
        />
      </form>
    </Modal>
  ) : null;
};

export default TotalRangePicker;
