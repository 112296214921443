const parseTime = (dateString, withUTC = true) => {
  const date = new Date(dateString);

  const options = {
    hour: "numeric",
    minute: "numeric",
    seconds: "numeric",
    hour12: true,
    timeZone: "UTC",
  };

  return date.toLocaleString("en-US", options) + (withUTC ? " UTC" : "");
};

export default parseTime;
