import React from "react";
import "./UserType.scss";
import {
  getTypeBGColor,
  renderTypeTitle,
} from "../../utils/businessTypeHelpers";

const UserType = ({ type, customClass }) => {
  return (
    <span
      className={`user-type ${getTypeBGColor(
        type,
        "user-type",
      )} ${customClass}`}
    >
      {renderTypeTitle(type)}
    </span>
  );
};

export default UserType;
