import React from "react";
import "./TransactionsLegend.scss";

const TransactionsLegend = () => {
  return (
    <div className="legend-tr__wrapper">
      <span className="legend-tr__element">Transactions</span>
      <span className="legend-tr__element">Date</span>
      <span className="legend-tr__element">Amount</span>
      <span className="legend-tr__element legend-tr__element-bigger">
        ID
      </span>
      <span className="legend-tr__element">Status</span>
    </div>
  );
};

export default TransactionsLegend;
