import { toast } from "react-toastify";
import AppStore from "../store/appStore";
import { toastOptions } from "../utils/constants/constants";
import { logout, refreshSession } from "./authentication";

class WebSocketService {
  constructor() {
    this.socket = null;
    this.sessionId = "";
    this.messageCallbacks = {};
    this.isConnected = false;
    this.pingInterval = null;
  }

  connect(token = "", navigate = () => {}) {
    this.socket = new WebSocket(process.env.REACT_APP_SERVER_URL);

    this.socket.onopen = () => {
      if (!window.location.href?.includes("mfa")) {
        AppStore.globalLoading = true;
      }

      this.isConnected = true;
      AppStore.isWSSConnected = true;
      if (token) {
        this.socket.send(
          JSON.stringify({
            token,
          }),
        );
      }
    };

    this.socket.onmessage = (event) => {
      const parsedRes = JSON.parse(event?.data);

      if (AppStore.globalLoading) {
        AppStore.globalLoading = false;
      }

      if (
        parsedRes?.status === "error" &&
        parsedRes?.error === "exp not satisfied"
      ) {
        this.stopPingInterval();
        refreshSession();
        return;
      }

      if (
        parsedRes?.status === "error" &&
        parsedRes?.error?.includes("not found")
      ) {
        this.stopPingInterval();
        logout();
        return;
      }

      if (parsedRes?.status === "failed") {
        if (!window.location.href?.includes("mfa")) {
          logout();
          window.location.href = "./";
        }
        toast.error("Authentication failed!", toastOptions);
      }

      if (
        parsedRes?.status === "ok" &&
        !AppStore.isAuth &&
        Object.keys(parsedRes)?.length === 1
      ) {
        window.location.href = "/";
        AppStore.isAuth = true;
      }

      if (!parsedRes) {
        return;
      }

      if (parsedRes?.type === "po") {
        return;
      }

      if (parsedRes?.type === "r") {
        if (this.messageCallbacks[parsedRes?.payload?.id]) {
          this.messageCallbacks[parsedRes.payload.id](
            parsedRes?.payload?.reply || parsedRes?.payload?.error,
          );
          delete this.messageCallbacks[parsedRes.payload.id];
        }
      } else {
        this.sessionId = parsedRes.id;
        AppStore.mfaStatus = parsedRes?.mfa;

        if (parsedRes?.mfa === "new") {
          navigate("../mfaconnect");
        }

        if (
          parsedRes?.mfa === "required" &&
          !window.location.href.includes("mfa")
        ) {
          window.location.href = "../mfa";
        }

        if (parsedRes?.mfa === "checked") {
          AppStore.isAuth = true;
          this.pingInterval = setInterval(() => {
            this.sendMessage("", "pi");
          }, 40000);
        }
      }
    };

    this.socket.onclose = () => {
      this.isConnected = false;
      this.stopPingInterval();

      setTimeout(() => {
        this.connect(localStorage.getItem("accessToken"));
      }, 1000);
    };
  }

  stopPingInterval() {
    if (this.pingInterval !== null) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  sendMessage(command, type, params, isPureBody = false) {
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        const id = this.generateMessageId();

        const messageWithId = isPureBody
          ? params
          : {
              type,
              payload: { id, command, params },
            };

        this.messageCallbacks[id] = resolve;
        this.socket.send(JSON.stringify(messageWithId));
      }
    });
  }

  generateMessageId() {
    return Math.random().toString(36).substring(2, 15);
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;
