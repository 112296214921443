import { calculateDecimal } from "./calculateDecimal";
import { cutWithoutRounding } from "./cutWithoutRounding";

export const getTotalVolumeTransactions = (data) => {
  const sum = data.reduce(
    (accumulator, currentValue) =>
      calculateDecimal(accumulator, +currentValue.volume, "add"),
    0,
  );

  return cutWithoutRounding(sum, 2);
};
