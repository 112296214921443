import React, { useEffect, useState } from "react";
import "./Mfa.scss";
import Logo from "../../assets/svgs/logo.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import webSocketService from "../../services/WebSocketService";
import Header from "../../components/Header/Header";
import OTPInput from "react-otp-input";
import { logout } from "../../services/authentication";

const Mfa = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  useEffect(() => {
    window.addEventListener("popstate", () => logout(), false);
    const token = localStorage.getItem("accessToken");
    if (!token) {
      window.location.href = "./";
    }
  }, []);
  const onPasteCode = async () => {
    await webSocketService.sendMessage(
      "",
      "",
      {
        totp: otp,
      },
      true,
    );
  };

  return (
    <div className="mfa__wrapper">
      <Header
        navigate={navigate}
        onBack={() => {
          logout();
          window.location.href = "./";
        }}
        goBack={false}
      />
      <img src={Logo} alt="logo" className="mfa__logo" />
      <div className="mfa__info">
        <span className="mfa__code">2FA</span>
        <span className="mfa__desc">
          Enter 6-digit code from your two-factor authentificator APP.
        </span>
      </div>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderInput={(props) => <input {...props} placeholder=" " />}
        inputStyle="mfa__input"
        containerStyle="mfa__input-container"
      />
      <Button
        disabled={otp?.length !== 6}
        label={otp?.length !== 6 ? `${6 - otp?.length} digits left` : "Next"}
        wrapperStyles="mfa__button"
        onPress={() => onPasteCode()}
        withArrow={true}
      />
    </div>
  );
};

export default Mfa;
