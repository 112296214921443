import React from "react";
import { transactionsFiltersList } from "../../utils/constants/constants";
import "./TransactionsFilters.scss";

const TransactionsFilters = ({ filter, setFilter }) => {
  return (
    <div className="tr-filters__wrapper">
      {transactionsFiltersList.map((el) => (
        <span
          key={el.name}
          className={`tr-filters__element ${
            filter === el.value ? "tr-filters__element-active" : ""
          }`}
          onClick={() => setFilter(el.value)}
        >
          <span>{el.name}</span>
        </span>
      ))}
    </div>
  );
};

export default TransactionsFilters;
