import React from "react";
import "./Input.scss";

const Input = ({
  value,
  onChange = () => {},
  label = "",
  placeholder = "",
  type = "",
  wrapperStyles = "",
  register = () => {},
  name = "",
  errors = {},
  isTextarea = false,
  inputStyles = "",
  rules = {},
  labelStyles = "",
  disabled = false,
}) => {
  return (
    <div className={`input__wrapper ${wrapperStyles || ""}`}>
      <span className={`input__label ${labelStyles}`}>{label}</span>
      {isTextarea ? (
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`input__field ${inputStyles} input__textarea`}
          placeholder={placeholder}
          type={type}
          {...register(name, rules)}
        />
      ) : (
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`input__field ${inputStyles}`}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          {...register(name, rules)}
        />
      )}
      <span className="input__error">{errors?.[name]?.message}</span>
    </div>
  );
};

export default Input;
