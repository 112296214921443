import React from "react";
import "./LegendUserPage.scss";

const LegendUserPage = () => {
  return (
    <div className="legend-user-page__wrapper">
      <span className="legend-user-page__element">E-mail</span>
      <span className="legend-user-page__element">Account age</span>
      <span className="legend-user-page__element legend-user-page__element-bigger">
        Referral code
      </span>
      <span className="legend-user-page__element">Device</span>
      <span className="legend-user-page__element">App version</span>
    </div>
  );
};

export default LegendUserPage;
