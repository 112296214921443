import React from "react";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import Button from "../Button/Button";
import "./ActionsUser.scss";

const ActionsUser = ({
  setShowCreditModal,
  userData,
  setShowChangeGroupModal,
}) => {
  return (
    <div className="actions-user__wrapper">
      <div className="actions-user__left">
        <Button
          label="Credit user"
          smaller
          onPress={() => setShowCreditModal(true)}
        />

        <Button label="Edit user" smaller />
      </div>
      <div className="actions-user__right">
        <div
          className="secondary-btn__wrapper secondary-btn__smaller"
          onClick={() => setShowChangeGroupModal(true)}
        >
          <span className="actions-user__single-title ">
            {capitalizeFirstLetter(userData?.restrictions?.settings?.name)}
            {" Limits Group "}
            <span className="actions-user__underline">Change</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActionsUser;
