import React, { useEffect, useState } from "react";
import "./Transactions.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import webSocketService from "../../services/WebSocketService";
import TransactionsFilters from "../../components/TransactionsFilters/TransactionsFilters";
import TransactionsLegend from "../../components/TransactionsLegend/TransactionsLegend";
import TransactionItem from "../../components/TransactionItem/TransactionItem";
import { MoonLoader } from "react-spinners";
import { loaderOverride } from "../../utils/constants/constants";
import useTransactionsFilters from "../../hooks/useTransactionsFilters";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/Input/Input";
import UserType from "../../components/UserType/UserType";

const Transactions = () => {
  const { userId, initialFilter } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [transactionsData, setTransactionsData] = useState([]);
  const [filter, setFilter] = useState(initialFilter || "all");
  const [textFilter, setTextFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [transfersData, setTransfersData] = useState([]);

  const filteredTransactions = useTransactionsFilters(
    transactionsData,
    transfersData,
    filter,
    textFilter,
  );

  const getTransactionsData = async (data) => {
    const transactions = await webSocketService.sendMessage(
      "getTransactions",
      "c",
      {
        userId,
      },
    );

    const transfers = await webSocketService.sendMessage("getTransfers", "c", {
      userId,
    });

    const user = await webSocketService.sendMessage("getUser", "c", {
      userId,
    });
    setTransfersData(transfers);
    setTransactionsData(transactions);
    setUserData(user);
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getTransactionsData();
    }, 2000);
  }, []);

  return !isLoading ? (
    <div className="transactions__wrapper">
      <div className="transactions__content">
        <Header withLogo navigate={navigate} />
        <span className="transactions__name">{`@${userData?.personal?.firstName} ${userData?.personal?.lastName}`}</span>
        <UserType type={userData?.type} />
        <div className="transactions__top-block">
          <span className="transactions__label">Transactions</span>
          <Input
            placeholder="Search"
            onChange={setTextFilter}
            wrapperStyles="transactions__filter"
            inputStyles="transactions__filter-text"
          />
        </div>

        <TransactionsFilters filter={filter} setFilter={setFilter} />
        <TransactionsLegend />
        {filteredTransactions.map((el) => (
          <TransactionItem item={el} key={el.id} filter={filter} />
        ))}
      </div>
      <Footer navigate={navigate} />
    </div>
  ) : (
    <div className="transactions__loader">
      <MoonLoader
        color="#7236CD"
        loading={isLoading}
        cssOverride={loaderOverride}
        size={35}
      />
    </div>
  );
};

export default Transactions;
