import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import AppStore from "../store/appStore";
import userpool from "./userpool";
import webSocketService from "./WebSocketService";

export const authenticate = (
  Email,
  Password,
  navigate = () => {},
  onFailure = () => {},
) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Email,
      Pool: userpool,
    });

    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const token = result.getAccessToken().getJwtToken();
        localStorage.setItem("accessToken", token);
        if (token) {
          webSocketService.connect(token, navigate);
        }
        resolve(result);
      },
      onFailure: (err) => {
        onFailure("! Error. Incorrect username or password.");
      },
    });
  });
};

export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    const user = userpool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          logout();
        } else {
          user.refreshSession(
            session.refreshToken,
            (refreshErr, refreshedSession) => {
              if (refreshErr) {
                logout();
              } else {
                const newToken = refreshedSession
                  .getAccessToken()
                  .getJwtToken();
                localStorage.setItem("accessToken", newToken);
                resolve(newToken);
              }
            },
          );
        }
      });
    }
  });
};

export const logout = () => {
  const user = userpool.getCurrentUser();
  if (user) {
    user.signOut();
  }
  AppStore.isAuth = false;
  localStorage?.setItem("accessToken", "");
  window.location.reload();
};
