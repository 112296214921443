import React from "react";
import "./HomeHeader.scss";
import Logo from "../../assets/svgs/logo.svg";
import BalanceItem from "../BalanceItem/BalanceItem";
import LogoutIcon from "../../assets/svgs/logoutIcon.svg";
import { logout } from "../../services/authentication";
import { cutWithoutRounding } from "../../utils/cutWithoutRounding";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

const HomeHeader = ({ balances }) => {
  const navigate = useNavigate();
  return balances ? (
    <div className="homeheader__wrapper">
      <img src={Logo} alt="logo" className="homeheader__logo" />
      <div className="homeheader__balances">
        <BalanceItem
          title="sFox Balance:"
          balance={`$ ${cutWithoutRounding(
            balances?.sfox?.treasury?.currencies?.USD?.balance,
            2,
          )} / BTC ${cutWithoutRounding(
            balances?.sfox?.treasury?.currencies?.BTC?.balance,
            8,
          )}`}
        />
        <BalanceItem
          title="Ibex Balance:"
          balance={`BTC ${cutWithoutRounding(
            balances?.ibex?.treasury?.currencies?.LN?.balance,
            8,
          )}`}
        />
        <BalanceItem
          title="Prizeout Balance:"
          balance={`$ ${balances?.prizeout?.treasury?.currencies?.USD?.balance}`}
        />
      </div>
      <div className="homeheader__buttons">
        <SecondaryButton
          title="User Limits"
          onClick={() => navigate("/limits")}
        />
        <div onClick={() => logout()} className="homeheader__button">
          <img src={LogoutIcon} alt="logout" />
        </div>
      </div>
    </div>
  ) : null;
};

export default HomeHeader;
