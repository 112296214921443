import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Close from "../../assets/svgs/close.svg";
import webSocketService from "../../services/WebSocketService";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import {
  currencyTypes,
  getModalCustomStyles,
  moduleTypes,
  toastOptions,
} from "../../utils/constants/constants";
import Button from "../Button/Button";
import CustomSelect from "../CustomSelect/CustomSelect";
import Input from "../Input/Input";
import "./CreditUserModal.scss";

const CreditUserModal = ({
  showCreditModal = true,
  setShowCreditModal,
  userId = "",
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState("");

  const onSubmit = async (data) => {
    setLoading(true);
    const creditData = { ...data, userId };
    const result = await webSocketService.sendMessage(
      "credit",
      "c",
      creditData,
    );

    if (result === "") {
      toast.success("Credit success!", toastOptions);
      setShowCreditModal(false);
    } else {
      toast.error(capitalizeFirstLetter(result), toastOptions);
    }
    setLoading(false);
  };

  return showCreditModal ? (
    <Modal
      isOpen={showCreditModal}
      style={getModalCustomStyles()}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <form className="credit-modal__wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div className="credit-modal__header">
          <span className="credit-modal__header-label">Credit User</span>
          <img
            className="credit-modal__close"
            src={Close}
            alt="close"
            onClick={() => setShowCreditModal(false)}
          />
        </div>
        <CustomSelect
          label="Type"
          name="module"
          control={control}
          options={moduleTypes}
          placeholder="Select type..."
          errors={errors}
          rules={{ required: "This field is required" }}
        />
        <CustomSelect
          label="Currency"
          name="currency"
          control={control}
          options={currencyTypes}
          placeholder="Select currency..."
          errors={errors}
          rules={{ required: "This field is required" }}
          onSelect={(coin) => {
            setSelectedCoin(coin);
            setValue("amount", "");
          }}
        />
        <Input
          label="Amount"
          placeholder="Enter amount"
          name="amount"
          register={register}
          errors={errors}
          inputStyles="credit-modal__input"
          rules={{
            required: "This field is required",
            pattern: {
              value:
                selectedCoin === "USD"
                  ? /^[0-9]+(.[0-9]{1,2})?$/
                  : /^[0-9]+(.[0-9]{1,8})?$/,
              message: `Integer or decimal numbers in the format ${
                selectedCoin === "USD" ? "XX.XX" : "XX.XXXXXXXX"
              }`,
            },
          }}
        />
        <Input
          label="Reason"
          placeholder="Enter reason"
          inputStyles="credit-modal__input"
          name="reason"
          register={register}
          errors={errors}
          isTextarea
        />
        <Button
          label="Run"
          type="submit"
          wrapperStyles="credit-modal__button"
          loading={loading}
        />
      </form>
    </Modal>
  ) : null;
};

export default CreditUserModal;
