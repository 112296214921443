import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Close from "../../assets/svgs/close.svg";
import webSocketService from "../../services/WebSocketService";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import {
  getModalCustomStyles,
  toastOptions,
} from "../../utils/constants/constants";
import Button from "../Button/Button";
import CustomSelect from "../CustomSelect/CustomSelect";
import "./ChangeGroupModal.scss";

const ChangeGroupModal = ({
  showModal = true,
  setShowModal = () => {},
  onSuccess = () => {},
  limitsData = [],
  userId = "",
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const assigningData = {
      userId,
      scheme: data.scheme,
    };

    const result = await webSocketService.sendMessage(
      "assignRestrictionScheme",
      "c",
      assigningData,
    );

    if (result === "") {
      toast.success("User group has been changed!", toastOptions);
      setShowModal(false);
      onSuccess();
    } else {
      toast.error(capitalizeFirstLetter(result), toastOptions);
    }
    setLoading(false);
  };

  useEffect(() => {}, []);

  return showModal ? (
    <Modal
      isOpen={showModal}
      style={getModalCustomStyles(250, { overflow: "visible" })}
      ariaHideApp={false}
    >
      <form
        className="change-group-modal__wrapper"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="change-group-modal__header">
          <span className="change-group-modal__header-label">Change Group</span>
          <img
            className="change-group-modal__close"
            src={Close}
            alt="close"
            onClick={() => setShowModal(false)}
          />
        </div>
        <CustomSelect
          label="Add to an existing group"
          name="scheme"
          control={control}
          options={limitsData}
          placeholder="Select group name..."
          errors={errors}
          rules={{ required: "This field is required" }}
        />

        <Button
          label="Add"
          type="submit"
          wrapperStyles="change-group-modal__button"
          loading={loading}
        />
      </form>
    </Modal>
  ) : null;
};

export default ChangeGroupModal;
