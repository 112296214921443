import { Bounce } from "react-toastify";

export const moduleTypes = [
  { value: "trade", label: "Credit (Trade)" },
  { value: "referral", label: "Rewards (Referral)" },
];

export const currencyTypes = [
  { value: "BTC", label: "BTC" },
  { value: "USD", label: "USD" },
];

export const getModalCustomStyles = (height = 500, contentStyles = {}) => {
  return {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: height,
      width: 384,
      backgroundColor: "#fff",
      borderRadius: "10px",
      ...contentStyles,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
};

export const customStylesSelect = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    outline: "none",
    borderRadius: "4px",
    backgroundColor: "#F7F7FD",
    height: 54,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 54,
    backgroundColor: "#F7F7FD",
    color: "#3D127D",
    paddingTop: 20,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#F7F7FD",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#F7F7FD",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#3D127D",
  }),
};

export const loaderOverride = {
  borderColor: "#7236CD",
};

export const transactionsFiltersList = [
  { name: "All", value: "all" },
  { name: "Deposits", value: "deposit" },
  { name: "Withdrawals", value: "withdraw" },
  { name: "Trades", value: "trade" },
  { name: "On Chain Sends", value: "onChainSend" },
  { name: "On Chain Receives", value: "onChainReceive" },
  { name: "Lightning Sends", value: "payOut" },
  { name: "Lightning Receives", value: "payIn" },
  { name: "Send Cash Transfers", value: "sendCashTransfers" },
  { name: "Invoice Transfers", value: "invoiceTransfers" },
  { name: "Credit", value: "credit" },
  { name: "Market", value: "market" },
  { name: "Rewards", value: "referral" },
  { name: "Fees", value: "fee" },
];

export const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export const BTC_ZERO_BALANCE = ".00000000";

export const totalsRanges = [
  { value: "thisMonth", label: "This month" },
  { value: "lastMonth", label: "Last month" },
  { value: "past7", label: "Past 7 days" },
  { value: "past30", label: "Past 30 days" },
  { value: "past90", label: "Past 90 days" },
  { value: "customized", label: "Customized" },
];

export const pickerStyle = { color: "#3d127d" };
