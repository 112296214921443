const useTransactionsFilters = (
  transactions = [],
  transfersData = [],
  filter = "",
  textFilter = "",
) => {
  let filteredTransactions = [];
  switch (filter) {
    case "all":
      filteredTransactions = transactions;
      break;
    case "deposit":
      filteredTransactions = transactions.filter(
        (el) =>
          el.type === "deposit" &&
          (el.custodial === "sfox" || el.custodial === ""),
      );
      break;
    case "withdraw":
      filteredTransactions = transactions.filter(
        (el) =>
          el.type === "withdraw" &&
          (el.custodial === "sfox" || el.custodial === ""),
      );
      break;
    case "trade":
      filteredTransactions = transactions.filter(
        (el) => el.type === "buy" || el.type === "sell",
      );
      break;
    case "onChainSend":
      filteredTransactions = transactions.filter(
        (el) => el.type === "withdraw" && el.module === "lightning",
      );
      break;
    case "onChainReceive":
      filteredTransactions = transactions.filter(
        (el) => el.type === "deposit" && el.module === "lightning",
      );
      break;
    case "payOut":
      filteredTransactions = transactions.filter(
        (el) => el.type === "payOut" && el.module === "lightning",
      );
      break;
    case "payIn":
      filteredTransactions = transactions.filter(
        (el) => el.type === "payIn" && el.module === "lightning",
      );
      break;
    case "credit":
      filteredTransactions = transactions.filter(
        (el) => el.type === "credit" && el.module !== "referral",
      );
      break;
    case "market":
      filteredTransactions = transactions.filter(
        (el) => el.type === "charge" && el.module === "market",
      );
      break;
    case "referral":
      filteredTransactions = transactions.filter(
        (el) => el.type === "credit" && el.module === "referral",
      );
      break;
    case "fee":
      filteredTransactions = transactions.filter((el) => el.type === "fee");
      break;
    case "sendCashTransfers":
      filteredTransactions = transfersData.filter((el) => el.type === "getbit");
      break;
    case "invoiceTransfers":
      filteredTransactions = transfersData.filter(
        (el) => el.type === "invoice",
      );
      break;
    default:
      filteredTransactions = transactions;
  }

  if (textFilter) {
    filteredTransactions = filteredTransactions?.filter((el) =>
      el?.id?.includes(textFilter),
    );
  }

  return filteredTransactions;
};
export default useTransactionsFilters;
